import { Checkbox, FormControlLabel, Input, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from 'reactstrap';
import Select from "react-select";
import { period } from '../../../../helpers/period';
import { limitType } from '../../../../helpers/limitType';
import { useSetState } from '../../../helpers/customHooks';
import { tariffService } from '../../../../services/tariffService';
import { toast } from 'react-toastify';
import { operationType } from '../../../../helpers/operationType';
import { countryService } from '../../../../services/countryService';
import Country from '../../../country';

const AddLimit = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search)

    const [limit, setLimit] = useSetState({
        tariffId: params.get('tariffId'),
        contractorId: params.get('contractorId'),
        totalAmount: "",
        period: "",
        limitType: "",
        countryId:"",
        operationType: ""
    });
    const options = [];
    const [countries, setCountries] = useState();
    const onLoadData = () => {
        countryService.getList().then((data) => {
            if (data) {
                data.items.map(country => {
                    options.push({ value: country.uid, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
                setCountries(options);
            }

        });
    }
    useEffect(() => {
        onLoadData();
    }, [])

    const CreateLimit = (e) => {
        tariffService.createLimit(JSON.stringify(limit)).then(mes=>{
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Лимит добавлен"}</p>)
            history.goBack();
        })
    };

    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h5>Добавить лимит</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Период</label>
                                            <Select onChange={e => {setLimit({ period: e.value })}} placeholder="Выбор периода" options={period} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна</label>
                                            <Select onChange={e => {setLimit({ countryId: e.value }) }} placeholder="Выбор страны" options={countries} name="country" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции</label>
                                            <Select onChange={e => setLimit({ operationType: e.value })} placeholder="Выбор типа операции" options={operationType} name="operationType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип лимита</label>
                                            <Select onChange={e => setLimit({ limitType: e.value })} placeholder="Выбор типа лимита" options={limitType} name="limitType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Сумма</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setLimit({ totalAmount: e.target.value })} type="text" id="totalAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <div className="list-container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Button id="delete" onClick={e => CreateLimit(e)} outline color="primary">Создать</Button>
                </div>
            </div>
        </Fragment >
    );
};

export default AddLimit;
