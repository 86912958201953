import React, { Fragment, useEffect, useRef, useState } from "react";
import { Loader } from "react-feather";
import { dashboardService } from "../../services/dashboardService";
import Breadcrumb from "../breadcrumb";
import { Chart } from "../helpers/chart";
import Select from "react-select";
import StatisticChart from "../staistic-chart";
import atob from "atob";

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CustomInput,
} from "reactstrap";

import "./css/dashboard.scss";
import { Switch } from "@mui/material";

const Dashboard = () => {
  const [isLoad, setLoad] = useState(false);
  const [isLoadOperations, setIsLoadOperations] = useState(false);
  const [operationData, setOperationData] = useState({});
  const [userData, setUserData] = useState({});
  const [operationCountData, setOperationCountData] = useState(null);
  const [operationSumData, setOperationSumData] = useState(null);
  const [activeUsersData, setActiveUsersData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interval, setInterval] = useState(2);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isLoadedDocument, setIsLoadedDocument] = useState(false);
  const [orderByStatus, setOrderByStatus] = useState(true);
  const [orderByDate, setOrderByDate] = useState(true);
  const intervals = [
    { value: 0, label: "День" },
    { value: 1, label: "Неделя" },
    { value: 2, label: "Месяц" },
  ];

  function onLoadData() {
    const from = startDate ? new Date(startDate).toUTCString() : "";
    const to = endDate ? new Date(endDate).toUTCString() : "";
    dashboardService.getOperationStatistic(from, to, interval).then((data) => {
      if (data) {
        setOperationData(data);
        initOperationCountChart(data.operationCount);
        initOperationSumChart(data.operationsSum);
        initActiveUsersChart(data.activeUsers);
      }
      setIsLoadOperations(true);
    });
    dashboardService.getUserStatistic(from, to).then((data) => {
      if (data) {
        initUsersChart(data);
      }
      setLoad(true);
    });
  }
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  function downloadFile(res, documentName) {
    const url = window.URL.createObjectURL(
      new Blob([s2ab(atob(res))], {
        type: "",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", documentName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  function loadSuccessfulTransfersDoc() {
    setIsLoadedDocument(true);
    dashboardService
      .getSuccessfulTransfersDoc(startDate, endDate)
      .then((res) => {
        downloadFile(res, "Динамика успешных переводов.xlsx");
        setIsLoadedDocument(false);
      });
  }

  function loadRegisterUsersDoc() {
    setIsLoadedDocument(true);
    dashboardService.getRegisterUsersDoc(startDate, endDate).then((res) => {
      downloadFile(res, "Кол-во зарегестрированных пользователей.xlsx");
      setIsLoadedDocument(false);
    });
  }

  function loadActiveUsersDoc() {
    setIsLoadedDocument(true);
    dashboardService.getActiveUsersDoc(startDate, endDate).then((res) => {
      downloadFile(res, "Активные пользователи по интервалам.xlsx");
      setIsLoadedDocument(false);
    });
  }

  function loadOperationsDoc() {
    setIsLoadedDocument(true);
    dashboardService
      .getOperationsDoc(startDate, endDate, orderByStatus, orderByDate)
      .then((res) => {
        downloadFile(res, "Активные пользователи по интервалам.xlsx");
        setIsLoadedDocument(false);
      });
  }

  function initUsersChart(data) {
    const datasets = {
      labels: data.map((item) => item.date),
      datasets: [
        initDataSets(
          "Пользователи",
          data.map((item) => item.usersCount),
          "#90EE90"
        ),
      ],
    };
    setUserData(datasets);
  }

  function initOperationCountChart(data) {
    const datasets = {
      labels: data.map((item) => item.date),
      datasets: [
        initDataSets(
          "Колличество операций",
          data.map((item) => item.count),
          "blue"
        ),
      ],
    };
    setOperationCountData(datasets);
  }

  function initOperationSumChart(data) {
    const datasets = {
      labels: data.map((item) => item.date),
      datasets: [
        initDataSets(
          "Сумма операций",
          data.map((item) => item.sum),
          "blue"
        ),
      ],
    };
    setOperationSumData(datasets);
  }

  function initActiveUsersChart(data) {
    const datasets = {
      labels: data.map((item) => item.date),
      datasets: [
        initDataSets(
          "Активные пользователи",
          data.map((item) => item.count),
          "green"
        ),
      ],
    };
    setActiveUsersData(datasets);
  }

  useEffect(() => {
    onLoadData();
  }, [startDate, endDate, interval]);

  const initDataSets = (label, data, color) => {
    return {
      label: label,
      data: data?.map((item) => item),
      borderColor: color,
      backgroundColor: color,
    };
  };

  if (!isLoad || !isLoadOperations) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Breadcrumb parent="Home" title="Дашборд" />
      <div className="info-container">
        <div className="statistic-chart">
          <StatisticChart
            num={operationData.avgOperations}
            name="Среднее кол-во переводов в день"
          />
        </div>
        <div className="statistic-chart">
          <StatisticChart
            num={Math.round(operationData.avgOperationSum)}
            name="Средняя сумма перевода"
          />
        </div>
      </div>
      <div className="container-fluid">
        <div className="charts">
          <div className="chart-container">
            <div className="info-container">
              <div className="form-group col-xl-3 col-md-3">
                <input
                  name="startDate"
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                  className="form-control"
                />
                <span>От</span>
              </div>
              <div className="form-group col-xl-3 col-md-3">
                <input
                  name="endDate"
                  type="date"
                  onChange={(event) => setEndDate(event.target.value)}
                  className="form-control"
                />
                <span>До</span>
              </div>
              <div className="form-group col-xl-3 col-md-6">
                <Select
                  onChange={(e) => {
                    setInterval(e.value);
                  }}
                  options={intervals}
                  placeholder="Выберите"
                />
                <span>Интервал</span>
              </div>
              {!isLoadedDocument ? (
                <Dropdown
                  isOpen={isOpenDropdown}
                  toggle={(e) => setIsOpenDropdown(!isOpenDropdown)}
                >
                  <DropdownToggle color="Light" caret>
                    Отчеты
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>
                      Выберите отчет, заранее проставив период
                    </DropdownItem>
                    <DropdownItem onClick={(e) => loadRegisterUsersDoc()}>
                      Зарегестрированные пользователи по месяцам
                    </DropdownItem>
                    <DropdownItem onClick={(e) => loadSuccessfulTransfersDoc()}>
                      Сумма переводов по месяцам
                    </DropdownItem>
                    <DropdownItem onClick={(e) => loadActiveUsersDoc()}>
                      Активные пользователи по интервалам
                    </DropdownItem>
                    <DropdownItem toggle={false} active={true}>
                      <div
                        className="operations"
                        onClick={(e) => loadOperationsDoc()}
                      >
                        Операции
                      </div>
                      <div className="switch">
                        <div>
                          <Switch
                            checked={orderByDate}
                            color="default"
                            onClick={(e) => setOrderByDate(!orderByDate)}
                          />
                        </div>
                        <div>Упорядочить по дате</div>
                      </div>
                      <div className="switch">
                        <div>
                          <Switch
                            checked={orderByStatus}
                            color="default"
                            onClick={(e) => setOrderByStatus(!orderByStatus)}
                          />
                        </div>
                        <div>Упорядочить по статусу</div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <div style={{ margin: "0.5%" }}>
                  <Loader />
                </div>
              )}
              <div className="form-group col-xl-1 col-md-6">
                <Button
                  onClick={(e) => {
                    setStartDate("");
                    setEndDate("");
                  }}
                >
                  x
                </Button>
              </div>
            </div>
            <Chart
              chartName="Активные пользователи"
              data={activeUsersData}
            ></Chart>
            <Chart chartName="Прирост пользователей" data={userData}></Chart>
            <Chart
              chartName="Колличество операций"
              data={operationCountData}
            ></Chart>
            <Chart chartName="Сумма операций" data={operationSumData}></Chart>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
