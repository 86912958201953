import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router";
import countries from "i18n-iso-countries";
import moment from "moment";
import { Button, Col, Row, ButtonGroup, Input, Breadcrumb } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from "react-select";
import { identificationService } from '../../../services/IdentificationService';
import ErrorAlert from '../../alert';
import { country } from '../../../helpers/country';
import { useSetState } from '../../helpers/customHooks';
import { allAppIdentificationStatuses, appIdentificationStatuses, appIdentificationStatusesForCourier, filterAppIdentificationStatuses } from '../../../helpers/statuses';
import { authService } from '../../../services/authService';
import TextField from '@material-ui/core/TextField';
import { checkAppIdentificationFields } from '../../helpers/validator';
import { width } from '@mui/system';
import 'moment/locale/ru'
import CustomLoader from '../../../helpers/customLoader';
import { defaultDate } from '../../../helpers/date';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../helpers/roleHelper';
import { Feedback } from '../../../constant';
import { partialIdentificationService } from '../../../services/partialIdentificationService';
import { userService } from '../../../services/userService';
import { GetImage } from '../identification/forms/helperDocumentForm';
import { getDocTypeLabel } from '../../../helpers/docTypeHelper';
import { partialIdentificationStatus, selectPartialIdentificationStatus } from '../../../helpers/partialIdentificationStatus';
import { partialIdentificationStatusHelper } from '../../../helpers/partialIdentificationStatusHelper';
moment.locale('ru')

const PartialIdentificationEdit = () => {
    const [oldDoc, setOldDoc] = useState();
    const [images, setImages] = React.useState([]);
    const [docType, setDocType] = useState();

    const [isLoad, setIsLoad] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false);

    const [comment, setComment] = useState('');

    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER, ROLES.SUPPORT, ROLES.IDENTIFICATIONADMINISTRATOR];
    const isCanIdentification = userRoleName == ROLES.IDENTIFICATIONAGENT;
    const dateConvert = (date, separator) => {
        date = date.split("T")[0];
        const split = date.split(separator);
        if (split.length > 1) {
            return split[2] + "-" + split[1] + "-" + split[0];
        }
        return date;
    }

    const [appIdentificationEntity, setAppIdentificationEntity] = useSetState({
        id: "",
        comment: "",
        createdAt: "",
        modifiedAt: "",
        createdBy: "",
        createdByFullName: "",
        modifiedByFullName: "",
    });
    const [userEntity, setUserEntity] = useSetState({
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: ""
    })

    const [identificationStatus, setIdentificationStatus] = useState(0)
    const { id } = useParams();

    const onLoad = async () => {
        setIsLoad(true);
        if (id && id != "edit") {
            await partialIdentificationService.getPartialIdentificationById(id).then(data => {
                setAppIdentificationEntity({
                    id: id,
                    createdAt: data.createdAt != defaultDate ? moment(data.createdAt).format('LLLL') : null,
                    modifiedAt: data.modifiedAt != defaultDate ? moment(data.modifiedAt).format('LLLL') : null,
                    modifiedBy: data.modifiedBy,
                    createdBy: data.createdBy,
                    modifiedByFullName: data.modifiedByFullName,
                    createdByFullName: data.createdByFullName,
                    comment: data.comment
                });
                setUserEntity({
                    id: data.userId,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber
                });
                // setIsReadOnly(data.modifiedBy && data.modifiedAt);
                setIdentificationStatus(data.status);
            })
        }
        setIsLoad(false)

    }

    const onSaveAppIdentification = async () => {
        const form = {
            comment: comment,
            status: identificationStatus
        }
        partialIdentificationService.updatePartialIdentificationStatus(appIdentificationEntity.id, JSON.stringify(form)).then(async (data) => {
            toast.info(<p>{"Статус успешно изменен!"}</p>);
            await onLoad();
        });
    }

    useEffect(async () => {
        await onLoad();
    }, [id])

    useEffect(async () => {
        setIsLoad(true);

        if(userEntity && userEntity.id){
            await userService.getDocumentsById(userEntity.id, '0').then(_ => {
                let doc = _ && _.documents && _.documents[0];
                setOldDoc({
                    countryCode: doc?.countryOfResidence,
                    serial: doc?.series,
                    number: doc?.number,
                    issueDate: doc?.issueDate && dateConvert(doc?.issueDate, "."),
                    expiryDate: doc?.expiryDate && dateConvert(doc?.expiryDate, "."),
                    authority: doc?.authority,
                    docImages: doc?.docImages
                });
            })

        }
        setIsLoad(false);

    }, [userEntity])

    useEffect(async () => {
        if (oldDoc?.docImages != null) {
            const images = await Promise.all(oldDoc.docImages?.map(item => GetImage(item)))
            setImages(images);
        }
    }, [oldDoc])

    if (isLoad) {
        return <CustomLoader />
    }
    return (
        <React.Fragment >

            <Breadcrumb parent="Заявка на идентификацию" title="Редактировать заявку на идентификацию" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Заявка</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" /></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">ФИО</label>
                                            <input className="form-control" defaultValue={userEntity.firstName + ' ' + userEntity.lastName + ' ' + userEntity.middleName}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" defaultValue={userEntity.phoneNumber || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Дата создания записи</label>
                                            <input className="form-control" defaultValue={appIdentificationEntity.createdAt || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        {appIdentificationEntity.modifiedAt ?
                                            <div className="form-group">
                                                <label className="form-label">Дата изменения записи</label>
                                                <input className="form-control" defaultValue={appIdentificationEntity.modifiedAt || ' '}
                                                    placeholder="your-email@domain.com" readOnly />
                                            </div> : <></>}
                                        <div className="form-group">
                                            <label className="form-label">Кто создал запись</label>
                                            <div className="form-control"
                                                readOnly>{!isReadOnly ? <Link to={"/user/edit/" + appIdentificationEntity.createdBy}>{appIdentificationEntity.createdByFullName}</Link> : appIdentificationEntity.createdByFullName}</div>
                                        </div>
                                        <div className="form-group">
                                            {appIdentificationEntity.modifiedBy ?
                                                <div className="form-group">
                                                    <label className="form-label">Кто изменил запись</label>
                                                    <div className="form-control"
                                                        readOnly>{!isReadOnly ? <Link to={"/user/edit/" + appIdentificationEntity.modifiedBy}>{appIdentificationEntity.modifiedByFullName}</Link> : appIdentificationEntity.modifiedByFullName}</div>
                                                </div> : <></>}
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <div className="row">


                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Код страны</label>
                                                <Input name="Serial" className="form-control" type="text"
                                                    defaultValue={oldDoc?.countryCode}
                                                    readOnly />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Серия</label>
                                                <Input name="Serial" className="form-control" type="text"
                                                    defaultValue={oldDoc?.serial}
                                                    readOnly />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Номер</label>
                                                <Input color="#FF0000" name="Number" className="form-control" type="text"
                                                    maxLength="10"
                                                    defaultValue={oldDoc?.number}
                                                    readOnly />
                                            </div>
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Кем выдан</label>
                                                <Input name="Authority" className="form-control" type="text"
                                                    defaultValue={oldDoc?.authority}
                                                    readOnly />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Дата выдачи</label>
                                                <Input name="IssueDate" className="form-control" type="date"
                                                    defaultValue={oldDoc?.issueDate}
                                                    readOnly />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Дата окончания</label>
                                                <Input name="ExpiryDate" className="form-control" type="date"
                                                    defaultValue={oldDoc?.expiryDate}
                                                    readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group">
                                    {images && images.length > 0 ? <label className="form-label">Фото</label> : <></>}
                                    {images.map((item, i) => {
                                        return (<div><img key={i.toString()} src={`${item.image}`} className="form-control" />
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className='row'>
                                <div className="col-xl-10 col-md-10">
                                    <div className="form-group">
                                        <label className="form-label">Статус идентификации</label>
                                        <Select
                                            onChange={e => {
                                                setIdentificationStatus(e.value);
                                                setComment('');
                                            }}
                                            options={selectPartialIdentificationStatus}
                                            defaultValue={partialIdentificationStatus.find(x => x.value == identificationStatus)}
                                            isDisabled={isReadOnly}
                                        />
                                        {identificationStatus === 2 ?
                                            <div>
                                                <label className="form-label">Комментарий</label>
                                                <Input name="Comment" className="form-control" type="text"
                                                defaultValue={appIdentificationEntity.comment}
                                                    onChange={e => { setComment(e.target.value) }}
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-xl-10 col-md-10">
                                    <button disabled={isReadOnly || (identificationStatus == 2 ? !comment : false)} className="btn btn-primary" onClick={event => onSaveAppIdentification()} type="submit">Сохранить</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default PartialIdentificationEdit;


