import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { getDateFull, getDateOnly } from "../../../../helpers/date";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import { adminColumns, commissionsColumns, limitsColumns, tariffsColumns } from '../../../../helpers/tableColumns';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../../helpers/roleHelper';
import { Button, Input } from 'reactstrap';
import { checkMessageFields } from '../../../helpers/validator';
import Breadcrumb from '../../../breadcrumb';
import DataTableComponent from '../../../dataTableComponent';
import { useSetState } from '../../../helpers/customHooks';
import PaginationComponent from '../../../pagination';
import { tariffService } from '../../../../services/tariffService';


const Tariff = () => {
    const [limits, setLimits] = useState([]);
    const [commissions, setCommission] = useState([]);
    const [contractorId, setContractorId] = useState('');
    const { id } = useParams();
    const [tariff, setTariff] = useSetState({
        id: id,
        description: "",
        title: "",
        name: "",
    });
    let commissionsTable = [];
    let limitsTable = [];

    const onLoadData = () => {
        tariffService.getTariff(id).then((data) => {
            if (data) {
                setContractorId(data.contractor.id);
                setTariff({
                    id: data.id,
                    description: data.description,
                    title: data.title,
                    name: data.name,
                });
                setCommission(data.commissions);
                setLimits(data.limits);
            }
        });
    }
    const history = useHistory();

    const goAddLimit = (e) => {
        history.push(`/add_limit?tariffId=${tariff.id}&contractorId=${contractorId}`)
    }

    const goAddCommission = (e) => {
        history.push(`/add_commission?tariffId=${tariff.id}&contractorId=${contractorId}`)
    }

    const saveTariff = (e) => {
        tariffService.updateTariff(JSON.stringify(tariff)).then((mes) => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Тариф сохранен"}</p>)
            history.goBack();
        })
    }

    const deleteTariff = (e) => {
        tariffService.deleteTariff(id).then((mes) => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Тариф удален"}</p>)
            history.goBack();
        })
    }
    useEffect(() => {
        onLoadData();
    }, [])

    if (commissions.length != 0) {
        commissions.forEach(commission => {
            const obj = {
                id: <Link to={"/commission/" + commission.id}>{commission.id}</Link>,
                value: commission.value,
                fromAmount: commission.fromAmount,
                toAmount: commission.toAmount,
                commissionType: commission.commissionType.name,
                operationType: commission.operationType.name,
                contractorName: commission.contractor.name,
                period: commission.period.name
            };
            commissionsTable = [...commissionsTable, obj];
        })
    }
    if (limits.length != 0) {
        limits.forEach(limit => {
            const obj = {
                id: <Link to={"/limit/" + limit.id}>{limit.id}</Link>,
                type: limit.type.name,
                period: limit.period.name,
                totalAmount: limit.totalAmount,
                contractorName: limit.contractor.name,

            };
            limitsTable = [...limitsTable, obj];
        })
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <h5>Тариф</h5>

                    <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                            <label className="form-label">Название</label>
                            <TextField variant="outlined" size="small" className="form-control"
                                onChange={e => setTariff({ name: e.target.value })} value={tariff.name} type="text" id="name" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                            <label className="form-label">Заголовок</label>
                            <TextField variant="outlined" size="small" className="form-control"
                                onChange={e => setTariff({ title: e.target.value })} value={tariff.title} type="text" id="title" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                            <label className="form-label">Описание</label>
                            <TextField variant="outlined" size="small" className="form-control"
                                onChange={e => setTariff({ description: e.target.value })} value={tariff.description} type="text" id="description" />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ justifyContent: 'space-araound' }}>
                    <Button className="btn btn-primary" onClick={e => saveTariff(e)} type="submit">Сохранить</Button>
                    <Button id="delete" onClick={e => deleteTariff(e)} outline color="info">Удалить</Button>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <h5>Комиссии</h5>
                    <Button className="btn btn-primary" onClick={e => goAddCommission(e)} type="submit">Добавить новую комиссию</Button>
                    <DataTableComponent data={commissionsTable} columns={commissionsColumns} />
                </div>
                <div className="row">
                    <h5>Лимиты</h5>
                    <Button className="btn btn-primary" onClick={e => goAddLimit(e)} type="submit">Добавить новый лимит</Button>
                    <DataTableComponent data={limitsTable} columns={limitsColumns} />
                </div>
            </div>
        </Fragment>
    );
};

export default Tariff;
