import { handlers } from "../helpers/hadlers"

export const operationService = {
    getTransfers,
    getAllOperationByUserId,
    chargeBack
};

const apiUrl = process.env.REACT_APP_API_URL;

export function getTransfers(page, source, country, operationStatus, sender, recepient, search, startDate, endDate, senderId, uid) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/operations?filter.country=${country}&filter.source=${source}&filter.operationStatus=${operationStatus}&filter.senderId=${senderId}
&filter.sender=${sender}&filter.recepient=${recepient}&filter.search=${search}&filter.startDate=${startDate}
&filter.endDate=${endDate}&filter.pageNumber=${page}&filter.pageSize=20&filter.uid=${uid}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getAllOperationByUserId(senderId, page) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/operations?filter.senderId=${senderId}&filter.pageNumber=${page}&filter.pageSize=20`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function chargeBack(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/v2/operations/chargeback", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
