export const commissionType =
    [
        { value: 0, label: "Фиксированная комиссия" },
        { value: 1, label: "Процент" }
    ];

    
export const getCommissionType = (name) => {
    switch (name) {
        case "Fix":
            return 0
        case "Percent":
            return 1
    }
}