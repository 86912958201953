export const partialIdentificationStatusHelper = (status) => {
    switch (status) {
        case "Pending":
            return "В ожидании"
        case "IdentificationFailed":
            return "Идентификация не пройдена"
        case "IdentificationPassed":
            return "Идентификация прошла успешно"
    }
}
