import { Checkbox, FormControlLabel, Input, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { countryService, getContractors } from '../../../services/countryService';
import Country from '../../country';
import { useSetState } from '../../helpers/customHooks';
import Loader from '../../loader';
import Select from "react-select";

const AddCountryCurrencies = () => {
    const [operationTypeId, setOperationTypeId] = useState('');
    const [contractorId, setContractorId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [countries, setCountries] = useState();
    const [contractors, setContractors] = useState();
    const [operationTypes, setOperationTypes] = useState();
    const currenciesChecked = [];
    const options = [];
    const optionsContractors = [];
    const optionsOperationTypes = [];

    const [isLoad, setLoad] = useState(true);

    const [datas, setData] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const history = useHistory();

    const [countryCurrency, setCountryCurrency] = useState([]);
    const [checked, setChecked] = useState([]);

    const onLoadData = () => {       
        countryService.getList().then((data) => {
            if (data) {
                data.items.map(country => {
                    options.push({ value: country.uid, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
                setCountries(options);
            }

        });

        countryService.getAllCurrencies().then((data) => {
            if (data) {
                setData(data);
            }
        });
        countryService.getContractors().then((data) => {
            if (data) {
                data.map(x => {
                    optionsContractors.push({ value: x.id, label: <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{x.name}</p> })
                });
                setContractors(optionsContractors);
            }
        });
        countryService.getOperationTypes().then((data) => {
            if (data) {
                data.map(x => {
                    optionsOperationTypes.push({ value: x.id, label: <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{x.name}</p> })
                });
                setOperationTypes(optionsOperationTypes);
            }
        });
        setLoad(false);
    }
   
    useEffect(() => {
         onLoadData();
    }, [])
   
    const handleCheck = (event, id) => {
        if (event.target.checked) {
            currenciesChecked.push(id);
            
        } else {
            currenciesChecked.pop(id);
        }
    };

    const AddCountryCurrency = (e) => {
        const obj = {
            countryId: countryId,
            operationTypeId: operationTypeId,
            contractorId: contractorId,
            currencyIds: currenciesChecked
        }
        countryService.addCountryCurrencies(JSON.stringify(obj)).then(mes => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Валютные пары добавлены"}</p>)
            history.goBack();
        })
    };

    const handleSearch = (e, type) => {
        if(type == "country"){
            setCountryId(e.value)
        }
        if(type == "operationType"){
            setOperationTypeId(e.value)
        }
        if(type == "contractor"){
            setContractorId(e.value)
        }
    };
   
    if(isLoad){
        return <Loader/>
    }
    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h5>Валютные пары</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна</label>
                                            <Select onChange={e => handleSearch(e, "country")} placeholder="Выбор страны" options={countries} name="country" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции</label>

                                            <Select onChange={e => handleSearch(e, "operationType")} placeholder="Выбор типа операции" options={operationTypes} name="operationTypes" />


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Контрагент</label>

                                            <Select onChange={e => handleSearch(e, "contractor")} placeholder="Выбор контрагента" options={contractors} name="contractors" />


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <div className="list-container">
                                    {datas.map((item, index) => (

                                        <div key={index}>
                                            <FormControlLabel control={<Checkbox  onChange={e => handleCheck(e, item.id)} />} label={item.currencyCode + " " + item.sign}></FormControlLabel>
                                         
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Button id="delete" onClick={e => AddCountryCurrency(e)} outline color="primary">Создать</Button>
                </div>
            </div>
        </Fragment >
    );
};

export default AddCountryCurrencies;
