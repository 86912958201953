import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import { Button, Input } from 'reactstrap';
import { useSetState } from '../../../helpers/customHooks';
import { tariffService } from '../../../../services/tariffService';
import { getPeriod, period } from '../../../../helpers/period';
import { getOperationType, operationType } from '../../../../helpers/operationType';
import { commissionType, getCommissionType } from '../../../../helpers/commissionType';
import { countryService } from '../../../../services/countryService';
import Country from '../../../country';

const Commission = () => {
    const { id } = useParams();
    const [commission, setCommission] = useSetState({
        id: "",
        value: "",
        fromAmount: "",
        toAmount: "",
        commissionType: "",
        operationType: "",
        contractor: "",
        period: "",
    });
    const options = [];
    const [countries, setCountries] = useState();

    const onLoadData = () => {
        countryService.getList().then((data) => {
            if (data) {
                data.items.map(country => {
                    options.push({ value: country.uid, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
                setCountries(options);
            }

        });
        tariffService.getCommission(id).then((data) => {
            if (data) {
                setCommission({
                    id: data.id,
                    value: data.value,
                    fromAmount: data.fromAmount,
                    toAmount: data.toAmount,
                    countryId: data.country?.id,
                    commissionType: getCommissionType(data.commissionType.name),
                    operationType: getOperationType(data.operationType.name),
                    contractor: data.contractor.id,
                    period: getPeriod(data.period.name)
                });
            }
        });
    }

    const history = useHistory();

    const saveCommission = (e) => {
        tariffService.updateCommission(JSON.stringify(commission)).then((mes) => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Сохранено"}</p>)
            history.goBack();
        })
    }

    const deleteCommission = (e) => {
        tariffService.deleteCommission(commission.id).then((mes) => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Удалено"}</p>)
            history.goBack();
        })
    }
    useEffect(() => {
        onLoadData();
    }, [])
    if (!commission || !countries) {
        return (<></>)
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Период</label>
                                            <Select onChange={e => { setCommission({ period: e.value }) }}
                                             value={{ value: commission.period, label: period.find(x => x.value == commission.period) && period.find(x => x.value == commission.period).label }}
                                                placeholder="Выбор периода" options={period} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна</label>
                                            <Select onChange={e => {setCommission({ countryId: e.value }) }} value={{ value: commission.countryId, label: countries.find(x => x.value == commission.countryId) && countries.find(x => x.value == commission.countryId).label }} placeholder="Выбор страны" options={countries} name="country" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции</label>
                                            <Select onChange={e => { setCommission({ operationType: e.value }) }} 
                                            value={{ value: commission.operationType, label: operationType.find(x => x.value == commission.operationType) && operationType.find(x => x.value == commission.operationType).label }}
                                                placeholder="Выбор типа операции" options={operationType} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип комиссии</label>
                                            <Select onChange={e => { setCommission({ commissionType: e.value }) }} 
                                            value={{ value: commission.commissionType, label: commissionType.find(x => x.value == commission.commissionType) && commissionType.find(x => x.value == commission.commissionType).label }}
                                                placeholder="Выбор типа комиссии" options={commissionType} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Значение</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ value: e.target.value })} value={commission.value} type="text" id="value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">От какой суммы действует комиссия</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ fromAmount: e.target.value })} value={commission.fromAmount} type="text" id="fromAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">До какой суммы действует комиссия</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ toAmount: e.target.value })} value={commission.toAmount} type="text" id="totalAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='row'>
                    <div>
                        <Button className="btn btn-primary" onClick={e => saveCommission(e)} type="submit">Сохранить</Button>
                    </div>
                    <div>
                        <Button id="delete" onClick={e => deleteCommission(e)} outline color="info">Удалить</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Commission;
