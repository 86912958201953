import { Checkbox, FormControlLabel, Input, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from 'reactstrap';
import Select from "react-select";
import { period } from '../../../../helpers/period';
import { useSetState } from '../../../helpers/customHooks';
import { tariffService } from '../../../../services/tariffService';
import { toast } from 'react-toastify';
import { operationType } from '../../../../helpers/operationType';
import { commissionType } from '../../../../helpers/commissionType';
import { countryService } from '../../../../services/countryService';
import Country from '../../../country';

const AddCommission = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search)

    const [comission, setCommission] = useSetState({
        tariffId: params.get('tariffId'),
        contractorId: params.get('contractorId'),
        period: "",
        operationType: "",
        commissionType: "",
        toAmount: "",
        value: "",
        fromAmount: "",
        countryId:"",
    });
    
    const options = [];
    const [countries, setCountries] = useState();
    const onLoadData = () => {
        countryService.getList().then((data) => {
            if (data) {
                data.items.map(country => {
                    options.push({ value: country.uid, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
                setCountries(options);
            }

        });
    }
    useEffect(() => {
        onLoadData();
    }, [])

    const CreateCommission = (e) => {
        tariffService.createCommission(JSON.stringify(comission)).then(mes => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Комиссия добавлена"}</p>)
            history.goBack();
        })
    };

    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h5>Добавить комиссиию</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Период</label>
                                            <Select onChange={e => { setCommission({ period: e.value }) }} placeholder="Выбор периода" options={period} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна</label>
                                            <Select onChange={e => {setCommission({ countryId: e.value }) }} placeholder="Выбор страны" options={countries} name="country" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции</label>
                                            <Select onChange={e => setCommission({ operationType: e.value })} placeholder="Выбор типа операции" options={operationType} name="limitType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Комиссия от суммы</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ fromAmount: e.target.value })} type="number" id="fromAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Комиссия до суммы</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ toAmount: e.target.value })} type="number" id="toAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Значение комиссии</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setCommission({ value: e.target.value })} type="number" id="value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип комиссии</label>
                                            <Select onChange={e => setCommission({ commissionType: e.value })} placeholder="Выбор типа комиссии" options={commissionType} name="limitType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <div className="list-container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Button id="delete" onClick={e => CreateCommission(e)} outline color="primary">Создать</Button>
                </div>
            </div>
        </Fragment >
    );
};

export default AddCommission;
