import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { getDateFull, getDateOnly } from "../../../../helpers/date";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import { adminColumns, commissionsColumns, limitsColumns, tariffsColumns } from '../../../../helpers/tableColumns';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../../helpers/roleHelper';
import { Button, Input } from 'reactstrap';
import { checkMessageFields } from '../../../helpers/validator';
import Breadcrumb from '../../../breadcrumb';
import DataTableComponent from '../../../dataTableComponent';
import { useSetState } from '../../../helpers/customHooks';
import PaginationComponent from '../../../pagination';
import { tariffService } from '../../../../services/tariffService';
import { getPeriod, period } from '../../../../helpers/period';
import { getLimitType, limitType } from '../../../../helpers/limitType';
import { getOperationType, operationType } from '../../../../helpers/operationType';
import { countryService } from '../../../../services/countryService';
import Country from '../../../country';


const Limit = () => {
    const { id } = useParams();
    const [limit, setLimit] = useSetState({
        id: id,
        contractor: "",
        totalAmount: "",
        period: "",
        limitType: "",
    });
    const options = [];
    const [countries, setCountries] = useState();

    const onLoadData = () => {
        countryService.getList().then((data) => {
            if (data) {
                data.items.map(country => {
                    options.push({ value: country.uid, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
                setCountries(options);
            }

        });
        tariffService.getLimit(id).then((data) => {
            if (data) {
                setLimit({
                    id: data.id,
                    totalAmount: data.totalAmount,
                    contractor: data.contractor.id,
                    countryId: data.country?.id,
                    period: getPeriod(data.period.name),
                    limitType: getLimitType(data.type.name),
                    operationType: data.operationType?.name && getOperationType(data.operationType.name)
                });
            }
        });
    }
    const history = useHistory();

    const saveLimit = (e) => {
        tariffService.updateLimit(JSON.stringify(limit)).then((mes) => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Лимит сохранен"}</p>)
            history.goBack();
        })
    }

    const deleteLimit = (e) => {
        tariffService.deleteLimit(id).then((mes)=>{
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Лимит удален"}</p>)
            history.goBack();
        })
    }

    useEffect(() => {
        onLoadData();
    }, [])

    if (!limit || !countries) {
        return (<></>)
    }
console.log("countries", countries)
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Период</label>
                                            <Select onChange={e => { setLimit({ period: e.value }) }} value={{ value: limit.period, label: period.find(x => x.value == limit.period) && period.find(x => x.value == limit.period).label }}
                                                placeholder="Выбор периода" options={period} name="period" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна</label>
                                            <Select onChange={e => {setLimit({ countryId: e.value }) }} value={{ value: limit.countryId, label: countries.find(x => x.value == limit.countryId) && countries.find(x => x.value == limit.countryId).label }} placeholder="Выбор страны" options={countries} name="country" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции</label>
                                            <Select onChange={e => setLimit({ operationType: e.value })} value={{ value: limit.operationType, label: operationType.find(x => x.value == limit.operationType) && operationType.find(x => x.value == limit.operationType).label }}
                                                placeholder="Выбор типа операции" options={operationType} name="operationType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип лимита</label>
                                            <Select onChange={e => setLimit({ limitType: e.value })} value={{ value: limit.limitType, label: limitType.find(x => x.value == limit.limitType) && limitType.find(x => x.value == limit.limitType).label }}
                                                placeholder="Выбор типа операции" options={limitType} name="limitType" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Сумма</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setLimit({ totalAmount: e.target.value })} value={limit.totalAmount} type="text" id="totalAmount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <div className="list-container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div>
                        <Button className="btn btn-primary" onClick={e => saveLimit(e)} type="submit">Сохранить</Button>
                    </div>
                    <div>
                        <Button id="delete" onClick={e => deleteLimit(e)} outline color="info">Удалить</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Limit;
