import { handlers } from "../helpers/hadlers"

const apiUrl = process.env.REACT_APP_API_URL;

export const tariffService = {
    getLimitByUserId,
    getTariffs,
    getTariff,
    createLimit,
    createCommission,
    getCommission,
    getLimit,
    updateLimit,
    deleteLimit,
    updateCommission,
    deleteCommission,
    createTariff,
    deleteTariff,
    updateTariff
};

export function updateTariff(message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/update_tariff", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteTariff(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + `api/admin/tariffs/delete_tariff?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function createTariff(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/add_tariff", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteCommission(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + `api/admin/tariffs/delete_commission?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function updateCommission(message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/update_commission", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteLimit(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + `api/admin/tariffs/delete_limit?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function updateLimit(message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/update_limit", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getCommission(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/tariffs/get_commission?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getLimit(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/tariffs/get_limit?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
export function createCommission(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/add_commission", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
export function createLimit(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/tariffs/add_limit", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
export function getLimitByUserId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "api/user/limits?userUid="+id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getTariffs() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "api/admin/tariffs/get_tariffs", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}


export function getTariff(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/tariffs/get_tariff?id=${id}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}