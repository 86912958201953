import { Checkbox, FormControlLabel, Input, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from 'reactstrap';
import Select from "react-select";
import Loader from '../../../loader';
import { useSetState } from '../../../helpers/customHooks';
import { tariffService } from '../../../../services/tariffService';
import { toast } from 'react-toastify';
import { countryService } from '../../../../services/countryService';

const AddTariff = () => {
    const [contractors, setContractors] = useState();
    const optionsContractors = [];
    const [isLoad, setLoad] = useState(true);
    const history = useHistory();

    const [tariff, setTariff] = useSetState({
        contractorId: "",
        name: "",
        title: "",
        description: "",
    });

    const onLoadData = () => {
        countryService.getContractors().then((data) => {
            if (data) {
                data.map(x => {
                    optionsContractors.push({ value: x.id, label: <p style={{ fontSize: 20, margin: 0, padding: 0 }}>{x.name}</p> })
                });
                setContractors(optionsContractors);
            }
        });
        setLoad(false);
    }

    useEffect(() => {
        onLoadData();
    }, [])

    const CreateTariff = (e) => {
        tariffService.createTariff(JSON.stringify(tariff)).then(mes => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Тариф добавлен"}</p>)
            history.goBack();
        })
    };

    if (isLoad) {
        return <Loader />
    }
    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h5>Добавить лимит</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-8 col-md-8">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Контрагент</label>
                                            <Select onChange={e => setTariff({ contractorId: e.value })} placeholder="Выбор контрагента" options={contractors} name="contractors" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Название</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setTariff({ name: e.target.value })} type="text" id="name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Заголовок</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setTariff({ title: e.target.value })} type="text" id="title" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-label">Описание</label>
                                            <TextField variant="outlined" size="small" className="form-control"
                                                onChange={e => setTariff({ description: e.target.value })} type="text" id="description" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Button id="create" onClick={e => CreateTariff(e)} outline color="primary">Создать</Button>
                </div>
            </div>
        </Fragment >
    );
};

export default AddTariff;
