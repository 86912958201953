import { Chip } from '@material-ui/core';
import { Checkbox, FormControlLabel, Input, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { countryService } from '../../../services/countryService';
import Country from '../../country';

const CountryCurrencies = () => {
    const [operationTypeId, setOperationTypeId] = useState('');
    const [contractorId, setContractorId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [datas, setData] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const history = useHistory();
    const [countryCurrency, setCountryCurrency] = useState([]);

    const onLoadData = () => {
        const params = new URLSearchParams(window.location.search)
        setCountryId(params.get('countryId'));
        setContractorId(params.get('contractorId'));
        setOperationTypeId(params.get('operationTypeId'));
        
        countryService.getCountryCurrency(params.get('countryId'), params.get('contractorId'), params.get('operationTypeId')).then((data) => {
            if (data) {
                setCountryCurrency({
                    countryCode: <Country code={data.countryCodes.name} size={20} />,
                    operationType: data.operationType.name,
                    contractorName: data.contractorName.name
                })
                setCurrencies(data.currencies);
            }
        })
        countryService.getAllCurrencies().then((data) => {
            if (data) {
                setData(data);
            }
        });
    }

    useEffect(() => {
        onLoadData();
    }, [])

    const handleCheck = (event, id) => {
        const obj = {
            countryId: countryId,
            currencyId: id,
            operationTypeId: operationTypeId,
            contractorId: contractorId
        }
        if (event.target.checked) {

            countryService.createCountryCurrency(JSON.stringify(obj)).then(mes => {
                toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Валюта добавлена"}</p>)
                onLoadData();
            })
        } else {
            countryService.deleteCountryCurrency(JSON.stringify(obj)).then(mes => {
                toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Валюта удалена"}</p>)
                onLoadData();
            })
        }
    };

    const deleteCountryCurrencies = (e) => {
        const obj = {
            countryId: countryId,
            operationTypeId: operationTypeId,
            contractorId: contractorId
        }
        countryService.deleteCountryCurrencies(JSON.stringify(obj)).then(mes => {
            toast.info(<p>{(mes && mes.controversy && mes.controversy[0]) || "Валютные пары удалены"}</p>)
            history.goBack();
        })
    };

    var isChecked = (item) => {
        if (currencies.length != 0 && currencies.filter(x => x.name == item).length > 0) {
            debugger;
            return true
        }
        return false;
    }


    if (datas == null && datas.length == 0) {
        return (<></>)
    }
    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h5>Валютные пары</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div className="row justify-content-between">
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Страна: </label>
                                            <Chip
                                                label={countryCurrency.countryCode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-xl-8 col-md-8">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Тип операции: </label>
                                            <Chip
                                                label={countryCurrency.operationType}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-md-12">
                                    <div className="form-group">
                                        <div className="list-container">
                                            <label className="form-label">Контрагент: </label>
                                            <Chip
                                                label={countryCurrency.contractorName}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <div className="title">Валюты:</div>
                                <div className="list-container">
                                    {datas.map((item, index) => (

                                        <div key={index}>
                                            <FormControlLabel control={<Checkbox checked={isChecked(item.currencyCode)} onChange={e => handleCheck(e, item.id)} />} label={item.currencyCode + " " + item.sign}></FormControlLabel>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Button id="delete" onClick={e => deleteCountryCurrencies(e)} outline color="info">Удалить</Button>
                </div>
            </div>
        </Fragment >
    );
};

export default CountryCurrencies;
