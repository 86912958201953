import { handlers } from "../helpers/hadlers";

const apiUrl = process.env.REACT_APP_API_URL;

export const countryService = {
    setStatus,
    getList,
    getCountryCurrencies,
    getAllCurrencies,
    getCountryCurrency,
    createCountryCurrency,
    deleteCountryCurrency,
    deleteCountryCurrencies,
    getOperationTypes,
    getContractors,
    addCountryCurrencies
};

export function addCountryCurrencies(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + `api/admin/add_country_currencies`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getOperationTypes() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/get_operation_types`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getContractors() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/get_contractors`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteCountryCurrencies(message) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + `api/admin/country_currencies`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteCountryCurrency(message) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + `api/admin/country_currency`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function createCountryCurrency(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + `api/admin/country_currency`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}


export function getCountryCurrency(countryId, contractorId, operationTypeId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/country_currency?countryId=${countryId}&contractorId=${contractorId}&operationTypeId=${operationTypeId}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getAllCurrencies() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/get_all_currencies`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
export function getCountryCurrencies(pageNumber, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/country_currencies?request.pagination.pageNumber=${pageNumber}&request.pagination.pageSize=${pageSize}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getList(pageNumber, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/countries?request.filter.pageNumber=${pageNumber}&request.filter.pageSize=${pageSize}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function setStatus(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/countries/status", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
