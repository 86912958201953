import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { getDateFull, getDateOnly } from "../../../../helpers/date";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import { adminColumns, tariffsColumns } from '../../../../helpers/tableColumns';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../../helpers/roleHelper';
import { Button, Input } from 'reactstrap';
import { checkMessageFields } from '../../../helpers/validator';
import Breadcrumb from '../../../breadcrumb';
import DataTableComponent from '../../../dataTableComponent';
import { useSetState } from '../../../helpers/customHooks';
import PaginationComponent from '../../../pagination';
import { tariffService } from '../../../../services/tariffService';


const Tariffs = () => {
    const [datas, setData] = useState([]);
    let dataTable = [];

    const onLoadData = () => {
        tariffService.getTariffs().then((data) => {
            if (data) {
                setData(data);
            }
        });
    }
    const history = useHistory();

    const goAddTariff = (e) => {
        history.push(`/add_tariff`)
    }

    useEffect(() => {
        onLoadData();
    }, [])

    if (datas.length != 0) {
        datas.forEach(data => {
            const obj = {
                id: <Link to={"/tariff/" + data.id}>{data.id}</Link>,
                title: data.title,
                name: data.name,
                description: data.description,
                contractorName: data.contractor.name,

            };
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                <Breadcrumb parent="Home" title="Тарифы" />
                    <Button className="btn btn-primary" onClick={e => goAddTariff(e)} type="submit">Добавить новый тариф</Button>
                    <DataTableComponent data={dataTable} columns={tariffsColumns} />
                </div>
            </div>
        </Fragment>
    );
};

export default Tariffs;
