export const period =
    [
        { value: 0, label: "Единоразовый" },
        { value: 1, label: "Один день" },
        { value: 30, label: "Месяц" },
    ];

    export const getPeriod = (name) => {
        switch (name) {
            case "OneTime":
                return 0
            case "LimitOneDay":
                return 1
            case "LimitThirtyDays":
                return 30
        }
    }