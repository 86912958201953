export const limitType =
    [
        { value: 0, label: "Лимит по переводу" },
        { value: 1, label: "Лимит по максимальному балансу по карте" },
        { value: 2, label: "Лимит по количеству переводов" },
    ];

    export const getLimitType = (name) => {
        switch (name) {
            case "Transfer":
                return 0
            case "MaxBalance":
                return 1
            case "CountTransfer":
                return 2
        }
    }