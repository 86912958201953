import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import Country from "../country";
import Switch from "react-switch";
import { countryService } from "../../services/countryService"
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';
import DataTableComponent from '../dataTableComponent';
import { countryCurrenciesColumns, userColumns } from '../../helpers/tableColumns';
import PaginationComponent from '../pagination';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const Countries = () => {
    let dataTable = [];
    let pageNumber = 1;
    let pageSize = 40;
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const [offset, setOffset] = useState(1);
    const [datas, setData] = useState([]);
    const [countryCurrencies, setCountryCurrencies] = useState([]);
    const [countElements, setCountElements] = useState(0);
    const update = async (e) => {
        pageSize = e / pageNumber + 1;
        await onLoadData();
    }
    const onLoadData = async () => {
        countryService.getList(pageNumber, pageSize).then((data) => {
            if (data) {
                setData(data.items);
            }
        });
        countryService.getCountryCurrencies(pageNumber, pageSize).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);

                setCountryCurrencies(data.items);
            }
        })
    }

    useEffect(async () => {
        await onLoadData();
    }, [])

    const handleChange = (checked, event, id) => {
        let form = {
            countryUid: id,
            isActive: checked
        }
        let result = countryService.setStatus(JSON.stringify(form)).then((_) => {
            onLoadData();
        });
    }

    const goCreate = (e) => {
        window.location.href = `${process.env.PUBLIC_URL}/countryCurrencies/addCountryCurrency`;
    }

    if (countryCurrencies.length != 0) {
        countryCurrencies.forEach(c => {
            const obj = {
                countryCode: <Country code={c.countryCodes.name} size={20} />,
                operationType: c.operationType.name,
                contractorName: c.contractorName.name,
                currencies: c.currencies.map(x => {
                    return x.name + " ";
                }),
                link: <Link to={`/countryCurrencies?countryId=${c.countryCodes.id}&operationTypeId=${c.operationType.id}&contractorId=${c.contractorName.id}`}>Перейти</Link>,

            }
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Направления" />
            <div className="card">
                <div className="card-header">
                    <p>Количество направлений переводов: {datas.length}</p>
                </div>
                <div className="card-body datatable-react">
                    <div className="container-fluid">
                        <div className="row">
                            {datas.map((item, key) =>
                                <div key={key} className="col-xl-4 col-sm-6">
                                    <div className="flex my-2">
                                        <Switch
                                            onChange={handleChange}
                                            id={item.uid}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            checked={item.isActive}
                                            onColor="#4466f2"
                                            disabled={isReadOnly}
                                            handleDiameter={16}
                                            height={20}
                                            width={35}
                                            className="switch-container"
                                        />
                                        <Country code={item.country3Code} size={20} />
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">

                <div>
                    <div>
                        <Button id="goCreate" onClick={e => goCreate(e)} color="primary">Создать</Button>

                    </div>

                    <DataTableComponent data={dataTable} columns={countryCurrenciesColumns} />
                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={pageSize}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default Countries;