import React, { useEffect, useState } from "react";
import { getDateFull } from "../helpers/date";
import { ReactComponent as SmallLogo } from "../assets/images/logo/small-logo-zamzam.svg";
import Country from "./country";
import { Link } from "react-router-dom";
import DataTableComponent from "./dataTableComponent";
import Loader from "./loader";
import PaginationComponent from "./pagination";
import StatisticChart from "./staistic-chart";
import {
    RotateCcw
} from 'react-feather';
import {
    ArrowRight
} from 'react-feather';
import { operationService } from "../services/operationService";
import { operationColumns} from "../helpers/tableColumns"
import { getStatusColor } from "../helpers/statuses";
import { getStatusText } from "../helpers/statuses";
import chargeBackLogo from "../assets/images/logo/chargeBack.png"
import { Button } from "reactstrap";
import { toast } from "react-toastify";

const OperationsTable = ({ search, children }) => {
    const pageSize = 20;
    const [offset, setOffset] = useState(0);
    const [visibleStatus, setVisibleStatus] = useState(true);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);

    let dataTable = [];

    const onLoadData = async () => {
        const data = await operationService.getTransfers(search.page, search.source, search.country, search.operationStatus, search.sender,
            search.recepient, search.search, search.startDate, search.endDate, search.senderId, search.uid)
            .then((data) => {
                if (data) {
                    setCountElements(data.pagination.totalRecords);
                    setData(data);
                }
            });
    }

    useEffect(async () => {
        await onLoadData();
    }, [search])


    const chargeBackOperation = async (id) => {
        let message = JSON.stringify({ uid: id });
        let result = await operationService.chargeBack(message)
            .then(_ => {
                toast.info("Возврат средств успешен!!");
            })
            .catch(error => {
                toast.error(<p>{error}</p>);
            });
    }

    const isAllowChargeBack = (statusNum) => {

        if (statusNum == "CancelError" || statusNum == "PayoutProcessError")
            return true;

        return false;
    }

    const update = async (e) => {
        search.page = e / pageSize + 1;
        await onLoadData();
    }

    if (datas.items) {
        datas.items.forEach(operation => {
            const obj = {
                date: operation.number ? <>{getDateFull(operation.createdUtcDateTime)}<br /><SmallLogo />{operation.uniqueNumber}<br />{operation.uid} <br />Юнистрим - {operation.number}</> :
                    <>{getDateFull(operation.createdUtcDateTime)}<br /><SmallLogo />{operation.uniqueNumber}<br />{operation.uid}</>,
                country: <Country code={operation.country2Code?.toLowerCase()} />,
                send: <>
                    <p>{operation.who?.lastName} {operation.who?.firstName}<br />
                        {operation.who?.middleName}
                    </p>
                    <Link
                        to={"/user/" + operation.who?.uid}>{operation.who?.phoneNumber}</Link>
                </>,
                get: <>
                    <p>{operation.whom?.lastName} {operation.whom?.firstName}<br />
                        {operation.whom?.middleName}
                    </p>
                    <Link
                        to={"/user/" + operation.whom?.uid}>{operation.whom?.phoneNumber ?? "-"}</Link>
                </>,
                currency: <>{operation.acceptedCurrency} - {operation.withdrawCurrency} </>,
                sum: operation.amount + " ₽ - " + operation.currencyAmount + (operation.withdrawCurrency === "USD" ? " $" : ""),
                rate: operation.rate + " ₽",
                commission: operation.totalCommissionAmount + " ₽",
                earn: operation.ourFeeAmount + operation.ourFixCommission + " ₽",
                status: <div className="flex"><span
                    className={`badge badge-pill badge-${getStatusColor(operation.operationStatus)}`}>{getStatusText(operation.operationStatus)}</span><RotateCcw
                        style={operation.operationStatus !== "Paid" ? { display: "none" } : { display: "block" }} className="ml-1"
                        width={15} /> {isAllowChargeBack(operation.operationStatus) ? <Button style={{ padding: 3 }} color="outline-light" onClick={e => chargeBackOperation(operation.uid)}> <img style={{ width: 20 }} src={chargeBackLogo} /></Button> : <></>} </div>
            };
            dataTable = [...dataTable, obj];

        })
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 col-xl-3" onClick={() => setVisibleStatus(!visibleStatus)} >
                    {visibleStatus ? 
                         <StatisticChart 
                         num={datas.items ? datas.pagination.totalRecords : 0}
                         name="Кол-во операций" 
                         classButton={visibleStatus}
                     /> : 
                        <>
                            <StatisticChart
                                num={datas.items ? datas.totalSuccessfulCount : 0}
                                name="Успешно"
                                visibleStatus={!visibleStatus}
                            />
                            <StatisticChart
                                num={datas.items ? datas.totalFailedCount : 0}
                                name="Неуспешно"
                                visibleStatus={!visibleStatus}
                            />
                        </>
                    }

                </div>
                <div className="col-sm-6 col-xl-3" onClick={() => setVisibleStatus(!visibleStatus)} >
                    {visibleStatus ? 
                        <StatisticChart
                            num={datas.items ? datas.totalSum : 0}
                            unit="₽"
                            name="Сумма операций"
                            classButton={visibleStatus} 
                        /> : 
                        <>
                            <StatisticChart
                                num={datas.items ? datas.totalSuccessfulSum : 0}
                                name="Успешно" 
                                visibleStatus={!visibleStatus}
                            />
                            <StatisticChart
                                num={datas.items ? datas.totalFailedSum : 0}
                                name="Неуспешно" 
                                visibleStatus={!visibleStatus}
                            />
                        </>
                    }
                </div>

                <div className="col-sm-6 col-xl-3">
                    <StatisticChart
                        num={datas.items ? datas.totalComission : 0}
                        unit="₽"
                        name="Сумма комиссии"
                    />

                </div>
                <div className="col-sm-6 col-xl-3">
                    <StatisticChart
                        num={datas.items ? datas.appCommission : 0}
                        unit="₽"
                        name="Доход Zamzam" 
                    />

                </div>
            </div>


                



            
            {children}

            {datas.items ?
                <>
                    <div className="table-mobile">
                        {datas.items ? datas.items.map(operation =>
                            <div className="card">
                                <div className="card-body">
                                    <div className="flex space-between mb-3">
                                        <div>{getDateFull(operation.createdUtcDateTime)}
                                        </div>
                                        <span
                                            className={`badge badge-pill badge-${getStatusColor(operation.operationStatus)}`}>{getStatusText(operation.operationStatus)}</span>
                                    </div>

                                    <div className="flex space-between">
                                        <div>
                                            <p>{operation.who?.lastName}<br /> {operation.who?.firstName}<br />
                                                {operation.who?.middleName ? (operation.who?.middleName.length < 10 ? operation.who?.middleName : operation.who?.middleName[0] + ".") : operation.who?.middleName}
                                            </p>
                                        </div>
                                        <div className="flex-centre">
                                            <>{operation.amount + " ₽ "}</>
                                            <ArrowRight /> <Country code={operation.country2Code?.toLowerCase()} />
                                            <>{operation.currencyAmount + (operation.withdrawCurrency === "USD" ? " $" : "")}</>

                                        </div>
                                        <div>
                                            <p>{operation.whom?.lastName}<br /> {operation.whom?.firstName}<br />
                                                {operation.whom?.middleName}
                                            </p>

                                        </div>

                                    </div>
                                    <div className="flex space-between">
                                        <Link
                                            to={"/user/" + operation.who?.uid}>{operation.who?.phoneNumber}</Link>
                                        <Link
                                            to={"/user/" + operation.whom?.uid}>{operation.whom?.phoneNumber}</Link>
                                    </div>
                                </div>
                            </div>
                        )
                            : <Loader />
                        }
                    </div>

                    <div className="table-main">
                        <DataTableComponent data={dataTable} columns={operationColumns} />
                    </div>
                </>
                : <Loader />
            }
            {countElements > 0 ?
                <PaginationComponent
                    setOffset={(e) => {
                        setOffset(e);
                        update(e);
                    }
                    }
                    totalArticles={datas.items ? datas.pagination.totalRecords : 6}
                    articlesPerPage={pageSize}
                /> : <></>
            }
        </div>
    )
}

export default OperationsTable;
