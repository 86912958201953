import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import App from "./components/app";
import AppProfile from "./components/appProfile"
import Dashboard from "./components/menu-pages/dashboard"
import Users from "./components/menu-pages/users";
import Operations from "./components/menu-pages/operations";
import Countries from "./components/menu-pages/countries";
import Login from './components/pages/login';
import profile from './components/pages/profile';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Provider, useSelector } from 'react-redux';
import configureStore from './store';
import UserEdit from "./components/users/userEdit";
import Admin from './components/menu-pages/admin';
// import AdminEditComponent from './components/menu-pages/admin/adminEditComponent'
import { ToastContainer } from 'react-toastify';
import OperationsModalComponent from './components/users/modals/operationsModalComponent';
import PartnerStatistics from './components/menu-pages/partnerStatistics';
import 'react-toastify/dist/ReactToastify.css';
import AboutPartner from './components/menu-pages/partnerStatistics/aboutPartner'
import { authService } from './services/authService';
import Orders from './components/menu-pages/orders';
import Roles from './components/menu-pages/roles';
import OrderCreate from './components/menu-pages/orders/orderCreate';
import OrderEdit from './components/menu-pages/orders/orderEdit';
import FmrEdit from './components/menu-pages/fmrBuildings/fmrEdit';
import FrmBuildings from './components/menu-pages/fmrBuildings';
import FmrCreate from './components/menu-pages/fmrBuildings/fmrCreate';
import AppIdentifications from './components/menu-pages/appIdentifications';
import AppIdentificationEdit from './components/menu-pages/appIdentifications/appIdentificationEdit';
import Identification from './components/menu-pages/identification/identification';
import CreateDocument from './components/menu-pages/identification/createDocument';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import ProtectedRoute from './components/helpers/protectedRout';
import RoleDetail from './components/menu-pages/role/roleDetail';
import { ROLES } from './helpers/roleHelper';
import SystemMessage from './components/menu-pages/admin/systemMessage/systemMessage';
import SystemMessageEditComponent from './components/menu-pages/admin/systemMessage/systemMessageEditComponent';
import PartialIdentifications from './components/menu-pages/partialIdentification';
import PartialIdentificationEdit from './components/menu-pages/partialIdentification/partialIdentificationEdit';
import CountryCurrencies from './components/menu-pages/country/countryCurrency';
import AddCountryCurrencies from './components/menu-pages/country/addCountryCurrency';
import Tariffs from './components/menu-pages/admin/limit/tariffs';
import Tariff from './components/menu-pages/admin/limit/tariff';
import AddLimit from './components/menu-pages/admin/limit/addLimit';
import AddCommission from './components/menu-pages/admin/limit/addCommission';
import Limit from './components/menu-pages/admin/limit/limit';
import Commission from './components/menu-pages/admin/limit/commission';
import AddTariff from './components/menu-pages/admin/limit/addTariff';

let store = configureStore();
const client = new ApolloClient({
    uri: 'https://hasura.zam.me/v1/graphql',
    cache: new InMemoryCache(),
    headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
});

const Root = () => {

    // @ts-ignore
    // @ts-ignore
    return (
        <div className="App">
            <ToastContainer position="top-right" limit={3} autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <BrowserRouter basename={`/`}>
                <Switch>
                    <Route path={`/login`}><Login /></Route>
                    {localStorage.getItem("token") ?
                        <div>
                            <App>
                                <Route exact path={`/`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Dashboard />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/dashboard`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Dashboard />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/users`}>
                                    <ProtectedRoute allowedRoles={[ROLES.AGENTFMR, ROLES.SUPPORT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <Users />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/user/edit/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.AGENTFMR, ROLES.SUPPORT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <UserEdit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/operations`}>
                                    <ProtectedRoute allowedRoles={[ROLES.SUPPORT, ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Operations />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/countries`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Countries />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/user/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.SUPPORT, ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <UserEdit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/admin`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Admin />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/admin/message`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <SystemMessage />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/message/edit/:id`}>
                                    <ProtectedRoute allowedRoles={[]}>
                                        <SystemMessageEditComponent />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/user/operation/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.SUPPORT, ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <OperationsModalComponent />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/partnerStatistics`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <PartnerStatistics />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/aboutPartner/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <AboutPartner />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/roles`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <Roles />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/role/:roleName`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <RoleDetail />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/fmrBuildings`}>
                                    <ProtectedRoute allowedRoles={[ROLES.DEVELOPER, ROLES.TESTER]}>
                                        <FrmBuildings />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/fmrBuilding/create`}>
                                    <ProtectedRoute allowedRoles={[]}>
                                        <FmrCreate />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/fmrBuilding/edit/:id`}>
                                    <ProtectedRoute allowedRoles={[]}>
                                        <FmrEdit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/appIdentifications`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <AppIdentifications />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/partialIdentifications`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <PartialIdentifications />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/partialIdentification/edit/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <PartialIdentificationEdit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/appIdentification/edit/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.DEVELOPER, ROLES.TESTER, ROLES.IDENTIFICATIONADMINISTRATOR]}>
                                        <AppIdentificationEdit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/identification/:id`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.AGENTFMR]}>
                                        <Identification />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/document/:id/:docTypeId`}>
                                    <ProtectedRoute allowedRoles={[ROLES.IDENTIFICATIONAGENT, ROLES.AGENTFMR]}>
                                        <CreateDocument />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/tariffs`}>
                                    <ProtectedRoute>
                                        <Tariffs />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/tariff/:id`}>
                                    <ProtectedRoute>
                                        <Tariff />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/add_limit`}>
                                    <ProtectedRoute>
                                        <AddLimit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/limit/:id`}>
                                    <ProtectedRoute>
                                        <Limit />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/commission/:id`}>
                                    <ProtectedRoute>
                                        <Commission />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/add_commission`}>
                                    <ProtectedRoute>
                                        <AddCommission />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/add_tariff`}>
                                    <ProtectedRoute>
                                        <AddTariff />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/countryCurrencies`}>
                                    <ProtectedRoute>
                                        <CountryCurrencies />
                                    </ProtectedRoute>
                                </Route>
                                <Route exact path={`/countryCurrencies/addCountryCurrency`}>
                                    <ProtectedRoute>
                                        <AddCountryCurrencies />
                                    </ProtectedRoute>
                                </Route>
                                {/* <Route path={`/orders/`} component={Orders} /> */}
                                {/* <Route path={`/order/create`} component={OrderCreate} />
                                    <Route path={`/orderEdit/:id`} component={OrderEdit} /> */}
                            </App>
                        </div>
                        :
                        <Redirect to={`/login`} />
                    }
                </Switch>
            </BrowserRouter>
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <Root />
            </ApolloProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
