export const operationType =
    [
        { value: 0, label: "Перевод с карты в наличку" },
        { value: 1, label: "Международные переводы с карты на карту" },
        { value: 2, label: "Пеереводы с карты зама на другие банки" },
        { value: 3, label: "Перевод с карты зама по сбп" },
        { value: 4, label: "Перевод с карты зам на карту зам" },
        { value: 5, label: "Пополнение" },
    ];

export const getOperationType = (name) => {
    switch (name) {
        case "TransferCard2Cash":
            return 0
        case "InternationalTransferCard2Card":
            return 1
        case "TransferCard2CardOtherBanks":
            return 2
        case "TransferCard2CardOtherBanksSbp":
            return 3
        case "TransferZam2Zam":
            return 4
        case "PayIn":
            return 5
    }
}